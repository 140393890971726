.watermark {
    height: 100%;
    display: block;
    position: relative;
  }
  
  .watermark::after {
    content: "";
   background:url(https://banner2.cleanpng.com/20180512/oxe/kisspng-paper-rubber-stamp-stock-photography-royalty-free-5af72d03738f71.0212011215261483554733.jpg) no-repeat;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  }